.card_Style {
  margin-bottom: 25px;
  border: none;
  border-radius: 0px;
  background-repeat: no-repeat;
  background-position: bottom;
  background-color: transparent;
  background-position-x: right;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}
.description {
  color: white;
  font-size: 15px !important;
  text-align: center;
  width: 70%;
  margin: 0 auto;
}

.description i {
  font-size: 24px;
  margin: 0 10px;
  color: #25a7de;
}

.imgcarousel img {
  height: 77vh;
  opacity: 0.47;
}
.imgcarousel {
  background-color: #0a224278 !important;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
}
.testimonials_rating {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.testimonials_rating div,
.testimonials_rating fieldset {
  max-width: fit-content !important;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.MuiBox-root.MuiBox-root-1 {
  display: flex;
  justify-content: center;
  text-align: center;
}
.MuiRating-root.MuiRating-readOnly {
  margin: auto !important;
}

@media (max-width: 767px) {
  .imgcarousel img {
    height: 50vh;
  }
  .description {
    width: 90%;
  }
}
