.scrolltoTop {
    position: fixed;
    bottom: 4.5rem;
    right: 0.5rem;
    animation: fadeIn 700ms ease-in-out 1s both;
    cursor: pointer;
    z-index:1020;
  }
  .scrolltoTop i{
    color: #26aae1;
font-size: 53px;
  }