* {
  font-family: 'Helvetica Neue', 'Open Sans', Helvetica, Arial, sans-serif;
}

@font-face {
  font-family: 'Lucky Fellas';
  src: url('../../fonts/LuckyFellas.woff2') format('woff2'),
    url('../../fonts/LuckyFellas.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Soleil Semi Bold';
  src: url('../../fonts/Soleil-SemiBold.woff2') format('woff2'),
    url('../../fonts/Soleil-SemiBold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


.hero_image {
  /* background-image: url('./images/HomepageBanner.jpg'); */
  background-image: url('./images/approvedBanner.jpg');
  height: 80vh;
  background-position: center -50px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  min-height: 45vh;
}
.contentDiv{
  position: absolute;
  top: 30%;
}


.headerText h1 {
  color: #083f88;
  font-size: 60px;
  font-weight: 600;
  line-height: 1;
  z-index: 2;
  position: relative;
  font-family: 'Soleil Semi Bold';
}
.hero_image > div {
  padding: 0 30px;
}

.headerText .trueHome {
  font-family: 'Lucky Fellas';
  font-size: 80px;
  font-weight: 500;
  color: #00aae4;
  line-height: 80px;
  letter-spacing: 0.012em;
  margin-top: 10px;
}

.promoImage {
  position: absolute;
  right: 0;
  top: 90px;
  width: 75px;
}

.btnRow{
  margin-top: 20px; 
  text-align: center;
}

.button{
  z-index: 2;
  width: 250px;
  font-size: 24px;
  font-weight: 600;
  background: #00aae4;
  border-color: transparent;
  border: none !important;
  margin-bottom: 10px;
  margin-right: 20px;
  border-radius: 25px;
}

/* @media (min-width: 320px) and (max-width:360px) {
 

  .promoImage{
    position: absolute;
    right: 0;
    top: 140px !important;
    width: 45px !important;
  }
}

@media (min-width: 361px) and (max-width:450px) {
  
  .promoImage{
    position: absolute;
    right: 0;
    top:150px !important;
    width: 50px;
  }
}

@media (min-width: 451px) and (max-width:550px) {
  
  .promoImage{
    position: absolute;
    right: 0;
    top:250px !important;
    width: 50px;
  }
} */

@media (min-width : 2160px){
  .contentDiv{
    left: 15%;
  }
}
@media (min-width: 1301px){
  .hero_image{
    min-height: auto !important;
    height: 600px !important;
    background-position: center right 35% !important;
  }
}


@media (min-width: 993px) and (max-width:1300px) {
  .hero_image{
    min-height: auto !important;
    height: 500px !important;
    background-position: center right 35% !important;
  }
}

@media (min-width: 768px) and (max-width:992px) {
  .contentDiv{
    top: 35%;
  }
  .hero_image{
    min-height: auto !important;
    height: 500px !important;
    background-position: center right 30% !important;
  }
  
  .headerText h1 {
    font-size: 50px;
  }

 .headerText .trueHome {
  font-size: 70px;
 }

 .button{
  width: 225px;
  font-size: 22px;
 }

}
@media (min-width: 451px) and (max-width:767px) {
  .hero_image{
    min-height: auto !important;
    height: 400px !important;
    background-position: center right 35% !important;
  }

  .promoImage{
    position: absolute;
    right: 0;
    top: 250px;
    width: 50px;
  }
}

@media (min-width: 310px) and (max-width:450px) {
  .hero_image{
    margin-top: 50px;
    min-height: auto !important;
    height: 50vh!important;
    background-size: cover;
    background-image: url('./images/approvedBannerMobile.jpg');
    background-position: center!important;
  }

  .hero_image::before{
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
  }

  .promoImage{
    position: absolute;
    right: 0;
    top: 260px !important;
    width: 45px !important;
  }
  .contentDiv{
    width: 100%;
    top: unset;
    bottom: 0%;
    padding: 1rem!important;
    text-align: center;
    background-color: #06397b;
    background-image: url(https://resources.swmc.com/swmc-images/minified/swmcGreybG.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    color: #fff !important;
  }

  .headerText h1 {
    font-size: 26px;
    color: #fff !important;
  }

  .headerText .trueHome {
    font-size: 36px;
    line-height: 55px;
  }
  .btnRow{
    padding: 0;
    margin-top: 5px;
    justify-content: space-around;
    flex-direction: row!important;
  }
  .button{
    padding: .25rem .5rem;
    width: 175px!important;
    margin: .5rem auto;
  }
}


@media (max-width: 767px){
    .btnRow{
      top: 54%;
      left: 0;
      right: 0;
      width: 100%;
  }
}

@media (max-width: 768px) {
  .hero_image h1 {
    font-size: 30px;
  }

  .btnRow{
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  .button{
    width: 220px;
    font-size: 18px;
  }

  .hero_image > div {
    padding: 0 20px;
  }
  .hero_image i {
    font-size: 40px;
  }
  .headerText .trueHome {
    font-size: 40px;
  }
  .promoImage{
    position: absolute;
    right: 0;
    top: 200px;
    width: 50px;
  }
}

@media (min-width:768px) and (max-width:900px){
  .promoImage{
    position: absolute;
    right: 0;
    top: 285px !important;
    width: 60px;
  }
}

@media (max-width: 399.98px){
  .hero_image{
    height: 55vh!important;
  }
  .contentDiv{
    padding: .5rem!important;
  }
  .hero_image h1 {
    font-size: 28px;
  }
  .headerText .trueHome {
    font-size: 32px;
    line-height: 35px;
  }
  .button{
    width: 140px!important;
  }
}

/* ----------- iPad 1, 2, Mini and Air ----------- */
/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .contentDiv{
      top: 45%;
    }
    .hero_image h1 {
      font-size: 55px;
    }
    .headerText .trueHome {
      font-size: 70px;
      line-height: 35px;
    }
    .btnRow{
      display: block;
    }
}

@media screen and (max-width:991.7px) {
  .userwaydivmobile{
      text-align: right;
  }
  .userwaydivmobile {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
}

/* @media only screen and (min-width: 768px) and (max-width: 991.7px) {
  .userwaydivmobile {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
} */

@media screen and (min-width:991.7px) {
  .userwaydivmobile {
    display: none !important;
  } 
} 


@media (min-width: 320px) and (max-width:991.7px) {
  .hero_image{
    z-index: -2;
  }
}