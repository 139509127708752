body {
    hyphens: auto;
}

.blogContents p {
    font-size: 18px;
    text-align: left!important;
}

a.dropdown-item {
    font-weight: 500!important;
    padding: 4px 16px!important;
}

.siteContents a {
    text-decoration: none!important;
}