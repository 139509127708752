body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media only screen and (min-width: 320px) and (max-width: 399.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 17px !important;
    left: 260px !important;
  }
}

@media only screen and (min-width: 400px) and (max-width: 600.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 17px !important;
    left: 280px !important;
  }
}

@media only screen and (min-width: 601px) and (max-width: 660.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 17px !important;
    left: 300px !important;
  }
}

@media only screen and (min-width: 661px) and (max-width: 710.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 17px !important;
    left: 300px !important;
  }
}

@media only screen and (min-width: 711px) and (max-width: 740.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 17px !important;
    left: 300px !important;
  }
}

@media only screen and (min-width: 741px) and (max-width: 767.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 17px !important;
    left: 300px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 820.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 17px !important;
    left: 300px !important;
  }
}

@media only screen and (min-width: 821px) and (max-width: 900.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 17px !important;
    left: 300px !important;
  }
}

@media only screen and (min-width: 901px) and (max-width: 960.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 17px !important;
    left: 300px !important;
  }
}

@media only screen and (min-width: 961px) and (max-width: 991.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 17px !important;
    left: 300px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1010.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 17px !important;
    left: 399px !important;
  }
}

@media only screen and (min-width: 1011px) and (max-width: 1023.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 17px !important;
    left: 396px !important;
  }
}

/* if device has a touch screen */
@media (any-pointer: coarse) {
  @media only screen and (min-width: 1024px) and (max-width: 1040.7px) {
    body .uwy.userway_p3 .uai {
      top: 17px !important;
      left: 300px !important;
    }
  }
}

/* if device has no touch screen */
@media (any-pointer: fine) {
  @media only screen and (min-width: 1024px) and (max-width: 1040.7px) {
    body .uwy.userway_p3 .uai {
      top: 17px !important;
      left: 300px !important;
    }
  }
}

@media only screen and (min-width: 1041px) and (max-width: 1070.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 17px !important;
    left: 300px !important;
  }
}

@media only screen and (min-width: 1071px) and (max-width: 1090.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 17px !important;
    left: 300px !important;
  }
}

@media only screen and (min-width: 1091px) and (max-width: 1099.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 17px !important;
    left: 300px !important;
  }
}

@media only screen and (min-width: 1100px) and (max-width: 1110.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 17px !important;
    left: 300px !important;
  }
}

@media only screen and (min-width: 1111px) and (max-width: 1130.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 17px !important;
    left: 300px !important;
  }
}

@media only screen and (min-width: 1131px) and (max-width: 1150.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 17px !important;
    left: 300px !important;
  }
}

@media only screen and (min-width: 1151px) and (max-width: 1170.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 17px !important;
    left: 300px !important;
  }
}

@media only screen and (min-width: 1171px) and (max-width: 1190.7px) {
  body .uwy.userway_p3 .uai ,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai{
    top: 17px !important;
    left: 300px !important;
  }
}

@media only screen and (min-width: 1191px) and (max-width: 1250.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 17px !important;
    left: 300px !important;
  }
}

@media only screen and (min-width: 1251px) and (max-width: 1290.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 17px !important;
    left: 300px !important;
  }
}

@media only screen and (min-width: 1291px) and (max-width: 1330.7px) {
  body .uwy.userway_p3 .uai {
    top: 17px !important;
    left: 300px !important;
  }
}

@media only screen and (min-width: 1331px) and (max-width: 1370.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 17px !important;
    left: 300px !important;
  }
}

@media only screen and (min-width: 1371px) and (max-width: 1399.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 17px !important;
    left: 300px !important;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1510.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 17px !important;
    left: 300px !important;
  }
}

@media only screen and (min-width: 1511px) and (max-width: 1580.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 17px !important;
    left: 300px !important;
  }
}

@media only screen and (min-width: 1581px) and (max-width: 1610.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 17px !important;
    left: 300px !important;
  }
}

@media only screen and (min-width: 1611px) and (max-width: 1650.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 17px !important;
    left: 300px !important;
  }
}

@media only screen and (min-width: 1651px) and (max-width: 1750.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 17px !important;
    left: 300px !important;
  }
}

@media only screen and (min-width: 1751px) and (max-width: 1850.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 17px !important;
    left: 300px !important;
  }
}

@media only screen and (min-width: 1851px) and (max-width: 1950.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 17px !important;
    left: 300px !important;
  }
}

@media only screen and (min-width: 1951px) and (max-width: 2010.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 17px !important;
    left: 300px !important;
  }
}

@media only screen and (min-width: 2011px) and (max-width: 2250.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 17px !important;
    left: 300px !important;
  }
}

@media only screen and (min-width: 2251px) and (max-width: 2440.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 17px !important;
    left: 300px !important;
  }
}

@media only screen and (min-width: 2441px) and (max-width: 2540.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 17px !important;
    left: 300px !important;
  }
}

@media only screen and (min-width: 2541px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 17px !important;
    left: 300px !important;
  }
}
@media only screen and (min-width: 3000px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 17px !important;
    left: 300px !important;
  }
}